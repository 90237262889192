import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './ContactUsSatelliteOffice.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import ContactUsBanner from '../../assets/images/contact-us-banner.png'
import Seo from '../../components/Seo/Seo'
import { MediaTypes } from '../../components/MediaGallery/MediaGallery'
import { toKebabCase } from '../../services/caseTransform'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ContactUsLayout from '../../layouts/ContactUsLayout/ContactUsLayout'

type ContactUsSatelliteOfficePropTypes = {
  data: {
    allShowroom: {
      nodes: {
        id: string
        showRoomTitle: string
        showroomList?: {
          items?: {
            id: string
            address: string
            telephoneHotline: string
            telephoneNumber: string
            title: string
          }[]
        }
        images: MediaTypes[]
        type: string
      }[]
    }
    allShowroomOffices: {
      nodes: {
        showRoomTitle: string
      }[]
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const ContactUsSatelliteOffice = ({
  data: {
    allShowroom: { nodes: allSatelliteOffice },
    allShowroomOffices: { nodes: allShowroomOffices },
    recentArticles: { nodes: recentArticles },
  },
}: ContactUsSatelliteOfficePropTypes): ReactElement => {
  const getBanner = (): string => {
    if (allSatelliteOffice) {
      return allSatelliteOffice[0]?.images
        ? allSatelliteOffice[0]?.images[0]?.url
        : ContactUsBanner
    }

    return ContactUsBanner
  }

  const initialShowroom = allShowroomOffices[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  const contactUsNavigation = CONTACT_US_NAVIGATION.map((item) => {
    return {
      url: item?.url,
      label: item.label,
      items: item.items?.map((val) => {
        return {
          url:
            val.url === 'contact-us/showroom'
              ? `contact-us/showroom/${showroomUrl}`
              : val.url,
          label: val.label,
        }
      }),
    }
  })

  return (
    <Layout>
      <Seo
        slug="contact-us/satellite-office"
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/contact-us/satellite-office/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, offices, satellite offices, avida offices',
        }}
        title="Contact Us | Satellite Office"
      />
      <ContactUsLayout className="">
        <ArticleLayout
          title="Contact Us"
          asideNavItems={contactUsNavigation}
          asideArticleTitle="News & Events"
          breadcrumbs={[
            {
              label: 'Contact Us',
              url: 'contact-us',
            },
            {
              label: 'Satellite Office',
            },
          ]}
          recentArticles={recentArticles}
          contentClassName="contact-us-satellite-office"
        >
          <LazyLoadImage
            src={getBanner()}
            alt="banner image"
            className="contact-us-satellite-office-banner"
          />
          <div className="contact-us-satellite-office-content-container">
            <h2 className="contact-us-satellite-office-title">
              Satellite Office
            </h2>
            {allSatelliteOffice.map((satelliteOffice) => (
              <React.Fragment key={satelliteOffice.id}>
                <h3 className="contact-us-satellite-office-location">
                  {satelliteOffice.showRoomTitle}
                </h3>
                <ul className="contact-us-satellite-office-location-list">
                  {satelliteOffice?.showroomList?.items?.map((office) => (
                    <li key={office.id}>
                      <h4>{office.title}</h4>
                      <p>{office.address}</p>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        </ArticleLayout>
      </ContactUsLayout>
    </Layout>
  )
}

export default ContactUsSatelliteOffice

export const pageQuery = graphql`
  query {
    allShowroom(filter: { type: { eq: "satellite-office-content" } }) {
      nodes {
        ...ShowroomFields
      }
    }
    allShowroomOffices: allShowroom(filter: { type: { eq: "" } }) {
      nodes {
        showRoomTitle
      }
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
